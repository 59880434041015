<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the predominant type of
        <span class="text-bold">intermolecular</span> force in each of the following substances:
      </p>

      <div v-for="(molecule, i) in molecules" :key="molecule">
        <stemble-latex :content="molecule" />
        <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
          <div v-for="option in options" :key="option.value">
            <v-radio class="d-inline-block pl-4" :value="option.value" />
            <stemble-latex :content="option.text" />
          </div>
        </v-radio-group>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

const ION_DIPOLE = 'a';
const HYDROGEN_BONDING = 'b';
const DIPOLE_DIPOLE = 'c';
const DISPERSION = 'd';

export default {
  name: 'Question60',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      molecules: [
        '$\\ce{a) PH3}$',
        '$\\ce{b) CH4}$',
        '$\\ce{c) HCOH}\\text{, Formaldehyde}$',
        '$\\ce{d) CH3CH2OH}\\text{, Ethanol}$',
        '$\\ce{e) H2S}$',
      ],
      options: [
        {text: 'Ion-Dipole', value: ION_DIPOLE},
        {text: 'Hydrogen Bonding', value: HYDROGEN_BONDING},
        {text: 'Dipole-Dipole', value: DIPOLE_DIPOLE},
        {text: 'Dispersion', value: DISPERSION},
      ],
    };
  },
};
</script>
